import { apiMaterialFlavorExport } from "@/request/api";
import { ElMessage } from "element-plus";
import { baseURL } from "@/request/http.js";
import { getStorage } from "@/utils";

export default (indexState, tableState) => {

    function exportExcel() {
        let tableData = [];
        switch (indexState.tableActive) {
            case 'score':
                tableData = tableState.score.tableData || [];
                break;
            case 'region':
                tableData = tableState.region.tableData || [];
                break;
            case 'trend':
                tableData = tableState.trend.tableData || [];
                break;
            case 'top':
                tableData = tableState.top.tableData || [];
                break;
        }
        if (tableData.length === 0) {
            ElMessage.error("暂无数据，请搜索");
            return;
        }
        const token = getStorage("token");
        const href = `${baseURL}${apiMaterialFlavorExport()}?token=${token}&code=${indexState.tableActive}&type=${indexState.menuActive}`;
        const a = document.createElement("a");
        a.href = href;
        a.download = href;
        a.click();
    }

    return { exportExcel }
}