import { reactive, nextTick } from "vue";
import { apiMaterialFlavorList, apiMaterialFlavorRegion, apiMaterialFlavorTrend, apiMaterialFlavorTop } from "@/request/api";
import { ElLoading, ElMessage } from 'element-plus';

export default (indexState, getWeightConfig) => {
    const state = reactive({
        // 评分信息
        score: {
            query: {
                pageNum: 1,
                pageSize: 100,
            },
            total: 0,
            tableData: [],
        },
        // 地域分布
        region: {
            query: {
                maxSize: 300,
                pageNum: 1,
                pageSize: 100,
            },
            total: 0,
            titleData: [],
            tableData: [],
        },
        // 季度趋势
        trend: {
            query: {
                maxSize: 300,
                pageNum: 1,
                pageSize: 100,
            },
            total: 0,
            titleData: [],
            tableData: [],
        },
        // 季度Top菜品
        top: {
            query: {
                maxSize: 300,
                pageNum: 1,
                pageSize: 100,
            },
            total: 0,
            titleData: [],
            tableData: [],
        },
    })

    // 获取列表
    function getList() {
        if (!indexState.query.batch) {
            ElMessage.error('请选择批次');
            return;
        }
        switch (indexState.tableActive) {
            case 'score':
                getScore()
                break;
            case 'region':
                getRegion()
                break;
            case 'trend':
                getTrend()
                break;
            case 'top':
                getTop()
                break;
        }
    }

    // 获取评分信息
    async function getScore() {
        let params = { ...indexState.query, ...state.score.query, type: indexState.menuActive, ...getWeightConfig() };
        params["sortField"] = "total_score";
        const loadingInstance = ElLoading.service({ text: '努力搜索中，请稍后...' })
        const { data } = await apiMaterialFlavorList(params);
        state.score.total = data?.total || 0;
        state.score.tableData = data?.list || [];
        nextTick(() => { loadingInstance.close() });
    }

    // 获取地域分布
    async function getRegion() {
        const params = { ...indexState.query, ...state.region.query, type: indexState.menuActive, ...getWeightConfig() };
        const loadingInstance = ElLoading.service({ text: '努力搜索中，请稍后...' })
        const { data } = await apiMaterialFlavorRegion(params);
        state.region.total = data?.total || 0;
        state.region.titleData = data?.title || [];
        state.region.tableData = data?.list || [];
        nextTick(() => { loadingInstance.close() });
    }

    // 获取季度趋势
    async function getTrend() {
        const params = { ...indexState.query, ...state.trend.query, type: indexState.menuActive, ...getWeightConfig() };
        const loadingInstance = ElLoading.service({ text: '努力搜索中，请稍后...' })
        const { data } = await apiMaterialFlavorTrend(params);
        state.trend.total = data?.total || 0;
        state.trend.titleData = data?.title || [];
        state.trend.tableData = data?.list || [];
        nextTick(() => { loadingInstance.close() });
    }

    // 获取季度Top
    async function getTop() {
        const params = { ...indexState.query, ...state.top.query, type: indexState.menuActive, ...getWeightConfig() };
        const loadingInstance = ElLoading.service({ text: '努力搜索中，请稍后...' })
        const { data } = await apiMaterialFlavorTop(params);
        state.top.total = data?.total || 0;
        state.top.titleData = data?.title || [];
        state.top.tableData = data?.list || [];
        nextTick(() => { loadingInstance.close() });
    }

    // 分页
    function pageChange() {
        getList();
    }

    // 搜索
    function search() {
        switch (indexState.tableActive) {
            case 'score':
                state.score.query.pageNum = 1;
                state.score.total = 0;
                state.score.tableData = [];
                break;
            case 'region':
                state.region.query.pageNum = 1;
                state.region.total = 0;
                state.region.tableData = [];
                break;
            case 'trend':
                state.trend.query.pageNum = 1;
                state.trend.total = 0;
                state.trend.tableData = [];
                break;
            case 'top':
                state.top.query.pageNum = 1;
                state.top.total = 0;
                state.top.tableData = [];
                break;
        }
        getList();
    }

    // 清空所有table/页码重置
    function clearTable() {
        // 评分信息
        state.score.query.pageNum = 1;
        state.score.total = 0;
        state.score.tableData = [];
        // 地域分布
        state.region.query.pageNum = 1;
        state.region.total = 0;
        state.region.titleData = [];
        state.region.tableData = [];
        // 季度趋势
        state.trend.query.pageNum = 1;
        state.trend.total = 0;
        state.trend.titleData = [];
        state.trend.tableData = [];
        // 季度top
        state.top.query.pageNum = 1;
        state.top.total = 0;
        state.top.titleData = [];
        state.top.tableData = [];
    }
    return { state, search, clearTable, getScore, getRegion, getTrend, getTop, pageChange }
}