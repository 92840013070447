<template>
    <el-dialog :model-value="show" title="权重配置" width="800px" top="10vh" @close="handleClose">
        <el-form :model="state.form" label-width="140px">
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item label="门店排序得分">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_shop_sort" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="品牌排序得分">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_brand_sort" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item label="城市排序得分">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_city_sort" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="省份排序得分">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_province_sort" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item label="推荐增速得分">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_recommend" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="店均推荐增速得分">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_recommend_avg" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item label="推荐总数排序得分">
                        <el-input type="number" placeholder="请输入" v-model.number="state.form.weight_recommend_sort" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="店均推荐数排序得分">
                        <el-input type="number" placeholder="请输入"
                            v-model.number="state.form.weight_recommend_sort_avg" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="btn_bar">
            <el-button @click="onReDefualt">恢复默认值</el-button>
            <el-button type="primary" @click="submit">确定</el-button>
        </div>
        <div class="table_bar">
            <table>
                <thead>
                    <tr>
                        <th>KPI</th>
                        <th>大类</th>
                        <th>统计值（得分）</th>
                        <th>默认</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td rowspan="8">客观性指标</td>
                        <td rowspan="4" class="back">集中度</td>
                        <td>门店排序得分-门店越少得分越高</td>
                        <td>12</td>
                    </tr>
                    <tr>
                        <td>品牌排序得分-品牌越少得分越高</td>
                        <td>10</td>
                    </tr>
                    <tr>
                        <td>城市排序得分-城市越少得分越高</td>
                        <td>6</td>
                    </tr>
                    <tr>
                        <td>省份排序得分-省份越少得分越高</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td rowspan="2" class="back">成长性</td>
                        <td>推荐增速得分（同比或环比，可选择）-增速越大得分越高</td>
                        <td>15</td>
                    </tr>
                    <tr>
                        <td>店均推荐增速得分（同比或环比，可选择）-增速越大得分越高</td>
                        <td>15</td>
                    </tr>
                    <tr>
                        <td rowspan="2" class="back">热度</td>
                        <td>推荐总数排序得分-推荐总数越大得分越高</td>
                        <td>20</td>
                    </tr>
                    <tr>
                        <td>店均推荐数排序得分-店均推荐数越大得分越高</td>
                        <td>20</td>
                    </tr>
                    <tr>
                        <td colspan="3">合计</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td colspan="4" class="text_red">注意：合计必须等于100</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </el-dialog>
</template>

<script setup>
import { reactive, watch } from 'vue';
import { getStorage } from '@/utils';
import { ElMessage } from 'element-plus';

const props = defineProps({
    show: { type: Boolean, defualt: false }
})

const emits = defineEmits(['close', 'reDefualt', 'setStorageConfig'])

const state = reactive({
    form: {
        weight_shop_sort: null,//门店排序得分
        weight_brand_sort: null,//品牌排序得分
        weight_city_sort: null,//城市排序得分
        weight_province_sort: null,//省份排序得分
        weight_recommend: null,//推荐增速得分
        weight_recommend_avg: null,//店均推荐增速得分
        weight_recommend_sort: null,//推荐总数排序得分
        weight_recommend_sort_avg: null,//店均推荐数排序得分 
    }
})

watch(() => props.show, (newVal) => {
    if (newVal) {
        readSetForm()
    }
})

// 读取缓存配置并给表单赋值
function readSetForm() {
    const weightConfig = getStorage('weightConfigMaterialFlavor');
    state.form = JSON.parse(weightConfig)
}

// 恢复默认值
function onReDefualt() {
    emits('reDefualt')
    readSetForm()
}

// 确定
function submit() {
    let total = 0;
    for (let key in state.form) {
        total += state.form[key]
    }
    if (total !== 100) {
        ElMessage.error('合计必须等于100')
        return
    }
    emits('setStorageConfig', state.form);// 保存到本地缓存
    handleClose()
}

// 关闭监听
function handleClose() {
    emits('close')
}
</script>

<style lang="scss" scoped>
.btn_bar {
    display: flex;
    justify-content: center;
    padding: 15px 0;
    padding-bottom: 30px;
}

.table_bar {
    table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
            border: 1px solid #ccc;
            text-align: center;
            padding: 5px 0;

            &.back {
                background-color: #f2f2f2;
            }

            &.text_red {
                color: red;
            }
        }

        th {
            background-color: #f2f2f2;
            color: #000;
        }
    }
}
</style>