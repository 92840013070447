export default function menuChangeHook(indexState, clearTable) {
    // 菜单改变
    function menuChange(id) {
        indexState.menuActive = id; // 改变当前选中菜单
        // 重置条件
        resetCondition();
        // 重置数据
        clearTable();
    }

    // 重置条件
    function resetCondition() {
        // 数据范围筛选
        indexState.query.province = [];//省份
        indexState.query.city = [];//城市
        indexState.query.ratioType = "环比";//比率
        indexState.query.mainType = [];//餐饮类型
        indexState.query.cuisineType = [];//菜系类型
        indexState.query.cuisine = [];//菜系
        indexState.query.materialMainType = [];//原料大类
        indexState.query.materialType = [];//原料二级类型
        indexState.query.materialSubType = [];//原料细类
        indexState.query.flavorMainType = [];//风味大类
        indexState.query.flavorType = [];//风味二级类型
        indexState.query.processMainType = [];//制作工艺
        indexState.query.styleType = [];//奉客形式
        indexState.query.shop_num_min = null; //品牌门店数
        indexState.query.shop_num_max = null;
        //计算结果筛选
        indexState.query.brand_num_min = null; //品牌数
        indexState.query.brand_num_max = null;
        indexState.query.province_num_min = null; //省份数
        indexState.query.province_num_max = null;
        indexState.query.city_num_min = null; //城市数
        indexState.query.city_num_max = null;
        indexState.query.brand_ratio_min = null; //品牌增长率
        indexState.query.brand_ratio_max = null;
        indexState.query.hot_score_min = null; //热度得分
        indexState.query.hot_score_max = null;
        indexState.query.dish_recommeds_rel_ratio_min = null; //总推荐数增长率
        indexState.query.dish_recommeds_rel_ratio_max = null;
        indexState.query.avg_recommeds_rel_ratio_min = null; //店均推荐数增长率
        indexState.query.avg_recommeds_rel_ratio_max = null;
        indexState.query.dish_recommends_num_min = null; //总推荐数
        indexState.query.dish_recommends_num_max = null;
        indexState.query.avg_recommends_num_min = null; //店均推荐数
        indexState.query.avg_recommends_num_max = null;
        indexState.query.city_ratio_min = null; //城市增长率
        indexState.query.city_ratio_max = null;
    }

    return {
        menuChange
    }
}