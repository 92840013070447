import { onBeforeMount, reactive } from "vue";
import { apiMaterialFlavorDict } from '@/request/api';
import { ElLoading } from 'element-plus';

// 参数选项
export default function paramsListHook({ loading, query }) {
    const state = reactive({
        batchList: [],// 批次
        provinceList: [],// 省份
        cityList: [],// 城市
        ratioTypeList: ['同比', '环比'],// 比率
        cuisineList: [],// 餐饮类型-菜系1级
        cuisine1List: [],// 菜系类型-菜系2级
        cuisineTypeList: [],// 菜系-菜系3级
        materialList: [],// 原料大类
        material1List: [],// 原料二级
        material2List: [],// 原料3级
        flavorList: [],//风味1
        flavor1List: [],//风味2
        processList: [],//制作工艺
        styleTypeList: [],// 奉客形式
        loading: {
            batch: false,// 批次
            province: false,// 省份
            city: false,// 城市
            cuisine: false,// 餐饮类型-菜系1级
            cuisine1: false,// 菜系类型-菜系2级
            cuisineType: false,// 菜系-菜系3级
            material: false,// 原料大类
            material1: false,// 原料二级
            material2: false,// 原料3级
            flavor: false,//风味1
            flavor1: false,//风味2
            process: false,//制作工艺
            styleType: false,// 奉客形式
        }
    })
    // 获取批次
    async function getBatch() {
        state.loading.batch = true;
        const { data } = await apiMaterialFlavorDict({ code: 'batch' });
        state.batchList = data || [];
        query.batch = state.batchList[0];
        state.loading.batch = false;
    }
    // 获取省份
    async function getProvince() {
        state.loading.province = true;
        const { data } = await apiMaterialFlavorDict({ code: 'province' })
        state.provinceList = data || [];
        state.loading.province = false;
    }
    // 获取城市
    async function getCity() {
        state.loading.city = true;
        const { data } = await apiMaterialFlavorDict({ code: 'city', province: query.province })
        state.cityList = data || [];
        state.loading.city = false;
    }
    // 获取餐饮类型-菜系一级
    async function getCuisine() {
        state.loading.cuisine = true;
        const { data } = await apiMaterialFlavorDict({ code: 'cuisine' });
        state.cuisineList = data || [];
        state.loading.cuisine = false;
    }
    // 获取菜系类型-菜系二级
    async function getCuisine1() {
        state.loading.cuisine1 = true;
        const { data } = await apiMaterialFlavorDict({ code: 'cuisine1', cuisine1: query.mainType });
        state.cuisine1List = data || [];
        state.loading.cuisine1 = false;
    }
    // 获取菜系-菜系三级
    async function getCuisine2() {
        state.loading.cuisineType = true;
        const params = { code: 'cuisine2', cuisine1: query.mainType, cuisine2: query.cuisineType };
        const { data } = await apiMaterialFlavorDict(params);
        state.cuisineTypeList = data || [];
        state.loading.cuisineType = false;
    }
    // 获取原料大类
    async function getMaterial() {
        state.loading.material = true;
        const { data } = await apiMaterialFlavorDict({ code: 'material' });
        state.materialList = data || [];
        state.loading.material = false;
    }
    // 获取原料二级类型
    async function getMaterial1() {
        state.loading.material1 = true;
        const { data } = await apiMaterialFlavorDict({ code: 'material1', material1: query.materialMainType });
        state.material1List = data || [];
        state.loading.material1 = false;
    }
    // 获取原料3级
    async function getMaterial2() {
        state.loading.material2 = true;
        const { data } = await apiMaterialFlavorDict({ code: 'material2', material1: query.materialMainType, material2: query.materialType });
        state.material2List = data || [];
        state.loading.material2 = false;
    }
    // 获取风味大类
    async function getFlavor() {
        state.loading.flavor = true;
        const { data } = await apiMaterialFlavorDict({ code: 'flavor' });
        state.flavorList = data || [];
        state.loading.flavor = false;
    }
    // 获取风味2级
    async function getFlavor1() {
        state.loading.flavor1 = true;
        const { data } = await apiMaterialFlavorDict({ code: 'flavor1', flavor: query.flavorMainType });
        state.flavor1List = data || [];
        state.loading.flavor1 = false;
    }
    // 获取制作工艺
    async function getProcess() {
        state.loading.process = true;
        const { data } = await apiMaterialFlavorDict({ code: 'process' });
        state.processList = data || [];
        state.loading.process = false;
    }
    // 获取奉客形式
    async function getStyleType() {
        state.loading.styleType = true;
        const { data } = await apiMaterialFlavorDict({ code: 'style' })
        state.styleTypeList = data || [];
        state.loading.styleType = false;
    }
    // 省份改变
    function changeProvince() {
        query.city = [];
        state.cityList = [];
        getCity();
    }
    // 餐饮类型改变
    function changeMainType() {
        query.cuisineType = [];//清空菜系类型
        query.cuisine = [];//清空菜系已选
        state.cuisine1List = [];
        state.cuisineTypeList = [];
        getCuisine1();//获取菜系
        getCuisine2();//获取菜系明细
    }
    // 菜系类型改变
    function changeCuisineType() {
        query.cuisine = [];//清空菜系已选
        state.cuisineTypeList = [];
        getCuisine2();//获取菜系明细
    }
    // 原料大类改变
    function changeMaterialMainType() {
        query.materialType = [];//原料2级
        query.materialSubType = [];//原料3级
        state.material1List = [];
        state.material2List = [];
        getMaterial1();//原料2级
        getMaterial2();//原料3级
    }
    // 原料2级改变
    function changeMaterialType() {
        query.materialSubType = [];//原料3级
        state.material2List = [];
        getMaterial2();//原料3级
    }
    // 风味1级改变
    function changeflavor() {
        query.flavorType = [];//风味2级
        state.flavor1List = [];
        getFlavor1();//风味2级
    }

    onBeforeMount(() => {
        getBatch();// 批次
        getProvince();// 省份
        getCity()// 城市
        getCuisine()// 餐饮类型-菜系1级
        getCuisine1();// 餐饮类型-菜系2级
        getCuisine2();// 餐饮类型-菜系3级
        getMaterial();//原料大类
        getMaterial1();//原料二级
        getMaterial2();//原料3级
        getFlavor();//风味1级
        getFlavor1();//风味2级
        getProcess();//制作工艺
        getStyleType();// 奉客形式
    })

    return { state, changeMainType, changeCuisineType, changeProvince, changeMaterialMainType, changeMaterialType, changeflavor }
}