import { reactive } from 'vue';
import { apiMaterialFlavorDishTop, apiMaterialFlavorExport } from '@/request/api';
import { getStorage } from '@/utils';
import { baseURL } from '@/request/http';

export default (indexState, getWeightConfig) => {
    const state = reactive({
        show: false,
        row: {},
        query: {
            pageNum: 1,
            pageSize: 300
        },
        loading: false,
        list: [],
    })

    // 打开弹框
    function open(row) {
        state.row = row;
        state.show = true;
        getTop300();
    }

    // 关闭弹框
    function close() {
        state.show = false;
        state.row = {};
        state.list = [];
    }

    // 获取数据
    async function getTop300() {
        state.loading = true;
        const params = { ...indexState.query, ...state.query, type: indexState.menuActive, flavor_standard: state.row.flavor_standard || null, material_standard: state.row.material_standard || null, ...getWeightConfig() }
        const { data } = await apiMaterialFlavorDishTop(params);
        state.list = data || [];
        state.loading = false;
    }

    // 下载
    function downloadTop300() {
        state.loading = true;
        const token = getStorage("token");
        const href = `${baseURL}${apiMaterialFlavorExport()}?token=${token}&code=dish&type=${indexState.menuActive}`;
        const a = document.createElement("a");
        a.href = href;
        a.download = href;
        a.click();
        state.loading = false;
    }

    return { state, open, close, downloadTop300 }
}