import { onBeforeMount, reactive } from 'vue';
import { getStorage, setStorage } from '@/utils';

export default () => {
    // 配置默认值
    const configDefult = {
        weight_shop_sort: 12,//门店排序得分
        weight_brand_sort: 10,//品牌排序得分
        weight_city_sort: 6,//城市排序得分
        weight_province_sort: 2,//省份排序得分
        weight_recommend: 15,//推荐增速得分
        weight_recommend_avg: 15,//店均推荐增速得分
        weight_recommend_sort: 20,//推荐总数排序得分
        weight_recommend_sort_avg: 20,//店均推荐数排序得分
    }
    const state = reactive({
        show: false,
    })

    // 打开弹框
    function open() {
        setDefualt()
        state.show = true;
    }

    // 关闭弹框
    function close() {
        state.show = false;
    }

    // 获取缓存的值，如果不存在，设置默认值
    function setDefualt() {
        const weightConfig = getStorage('weightConfigMaterialFlavor');
        if (!weightConfig) {
            setStorageConfig(configDefult)
        }
    }

    // 将配置表单缓存到本地
    function setStorageConfig(form) {
        setStorage('weightConfigMaterialFlavor', JSON.stringify(form))
    }

    // 恢复默认值
    function reDefualt() {
        setStorageConfig(configDefult)
    }

    // 获取配置对象
    function getWeightConfig() {
        return JSON.parse(getStorage('weightConfigMaterialFlavor'))
    }

    onBeforeMount(() => {
        setDefualt()
    })

    return { state, open, close, reDefualt, getWeightConfig, setStorageConfig }
}