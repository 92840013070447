<template>
    <el-dialog :model-value="show" title="TOP300菜品" width="1200px" top="5vh" @close="handleClose">
        <div class="dialog_box" v-loading="loading">
            <section class="le_box">
                <div class="li" v-if="row.material_standard">
                    <span>原料标准词：</span>
                    <p>{{row.material_standard}}</p>
                </div>
                <div class="li" v-if="row.flavor_standard">
                    <span>风味标准词：</span>
                    <p>{{row.flavor_standard}}</p>
                </div>
                <div class="li">
                    <span>总得分：</span>
                    <p>{{row.total_score}}</p>
                </div>
                <div class="li">
                    <span>门店数得分：</span>
                    <p>{{row.shop_num_score}}</p>
                </div>
                <div class="li">
                    <span>品牌数得分：</span>
                    <p>{{row.brand_num_score}}</p>
                </div>
                <div class="li">
                    <span>省份数得分：</span>
                    <p>{{row.province_num_score}}</p>
                </div>
                <div class="li">
                    <span>城市数得分：</span>
                    <p>{{row.city_num_score}}</p>
                </div>
                <div class="li">
                    <span>集中度得分：</span>
                    <p>{{row.region_score}}</p>
                </div>
                <div class="li">
                    <span>总推荐数比率得分：</span>
                    <p>{{row.recommends_rel_ratio_score}}</p>
                </div>
                <div class="li">
                    <span>店均推荐数增长率得分：</span>
                    <p>{{row.avg_recommends_rel_ratio_score}}</p>
                </div>
                <div class="li">
                    <span>成长性得分：</span>
                    <p>{{row.growth_score}}</p>
                </div>
                <div class="li">
                    <span>总推荐数得分：</span>
                    <p>{{row.dish_recommends_num_score}}</p>
                </div>
                <div class="li">
                    <span>店均推荐数得分：</span>
                    <p>{{row.avg_recommends_num_score}}</p>
                </div>
                <div class="li">
                    <span>热度得分：</span>
                    <p>{{row.hot_score}}</p>
                </div>
                <el-button type="primary" @click="downloadFn">下载excel</el-button>
            </section>
            <section class="ri_box">
                <div class="li" v-for="item in list" :key="item">{{item.dish_name}}</div>
            </section>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="handleClose">关闭</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>

defineProps({
    show: { type: Boolean, defualt: false },
    loading: { type: Boolean, defualt: false },
    row: { type: Object, defualt: {} },
    list: { type: Array, defualt: [] }
})

const emits = defineEmits(['close', 'downloadTop300']);

// 关闭监听
function handleClose() {
    emits('close')
}

// 下载
function downloadFn() {
    emits('downloadTop300')
}
</script>

<style lang="scss" scoped>
.dialog_box {
    display: flex;

    .le_box {
        width: 200px;

        .li {
            display: flex;
            align-items: center;
            padding-bottom: 15px;
        }
    }

    .ri_box {
        flex: 1;
        min-height: 500px;
        border: 1px solid #ccc;
        padding: 10px;


        .li {
            display: inline-block;
            border: 1px solid #ccc;
            line-height: 30px;
            height: 30px;
            padding: 0 5px;
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
}
</style>