<template>
  <div class="home" v-loading="state.loading">
    <!-- 头部 -->
    <my-header @outLoginFun='outLoginFun' :menuList="state.menuList" v-model:menuActive='state.menuActive'
      @change='menuChange' @weightOpen="weightOpen"></my-header>
    <!-- 数据范围筛选 -->
    <div class="row_box">
      <div class="tit_box">
        <small-title tit="数据范围筛选"></small-title>
      </div>
      <div class="search_box">
        <div class="li">
          <h6>批次：</h6>
          <el-select v-model="state.query.batch" class="m-2" placeholder="全部" filterable
            :loading="paramsState.loading.batch">
            <el-option v-for="item in paramsState.batchList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>省份：</h6>
          <el-select v-model="state.query.province" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags :loading="paramsState.loading.province" @change="changeProvince">
            <el-option v-for="item in paramsState.provinceList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>城市：</h6>
          <el-select v-model="state.query.city" class="m-2" placeholder="全部" filterable clearable multiple collapse-tags
            :loading="paramsState.loading.city">
            <el-option v-for="item in paramsState.cityList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>比率：</h6>
          <el-select v-model="state.query.ratioType" class="m-2" placeholder="全部">
            <el-option v-for="item in paramsState.ratioTypeList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>餐饮类型：</h6>
          <el-select v-model="state.query.mainType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags :loading="paramsState.loading.cuisine" @change="changeMainType">
            <el-option v-for="item in paramsState.cuisineList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>菜系类型：</h6>
          <el-select v-model="state.query.cuisineType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags :loading="paramsState.loading.cuisine1" @change="changeCuisineType">
            <el-option v-for="item in paramsState.cuisine1List" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>菜系：</h6>
          <el-select v-model="state.query.cuisine" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags :loading="paramsState.loading.cuisineType">
            <el-option v-for="item in paramsState.cuisineTypeList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>原料大类：</h6>
          <el-select v-model="state.query.materialMainType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags :loading="paramsState.loading.material" @change="changeMaterialMainType">
            <el-option v-for="item in paramsState.materialList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>原料二级类型：</h6>
          <el-select v-model="state.query.materialType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags :loading="paramsState.loading.material1" @change="changeMaterialType">
            <el-option v-for="item in paramsState.material1List" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>原料细类：</h6>
          <el-select v-model="state.query.materialSubType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags :loading="paramsState.loading.material2">
            <el-option v-for="item in paramsState.material2List" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>风味大类：</h6>
          <el-select v-model="state.query.flavorMainType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags :loading="paramsState.loading.flavor" @change="changeflavor">
            <el-option v-for="item in paramsState.flavorList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>风味二级类型：</h6>
          <el-select v-model="state.query.flavorType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags :loading="paramsState.loading.flavor1">
            <el-option v-for="item in paramsState.flavor1List" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>制作工艺：</h6>
          <el-select v-model="state.query.processMainType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags :loading="paramsState.loading.process">
            <el-option v-for="item in paramsState.processList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>奉客形式：</h6>
          <el-select v-model="state.query.styleType" class="m-2" placeholder="全部" filterable clearable multiple
            collapse-tags :loading="paramsState.loading.styleType">
            <el-option v-for="item in paramsState.styleTypeList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="li">
          <h6>品牌门店数：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.shop_num_min"></el-input>-
            <el-input type="number" v-model="state.query.shop_num_max"></el-input>
          </div>
        </div>
      </div>
    </div>
    <!-- 计算结果筛选 -->
    <div class="row_box">
      <div class="tit_box">
        <small-title tit="计算结果筛选"></small-title>
      </div>
      <div class="search_box">
        <div class="li">
          <h6>品牌数：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.brand_num_min"></el-input>-
            <el-input type="number" v-model="state.query.brand_num_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>省份数：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.province_num_min"></el-input>-
            <el-input type="number" v-model="state.query.province_num_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>城市数：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.city_num_min"></el-input>-
            <el-input type="number" v-model="state.query.city_num_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>品牌增长率：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.brand_ratio_min"></el-input>-
            <el-input type="number" v-model="state.query.brand_ratio_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>热度得分：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.hot_score_min"></el-input>-
            <el-input type="number" v-model="state.query.hot_score_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>总推荐数增长率：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.dish_recommeds_rel_ratio_min"></el-input>-
            <el-input type="number" v-model="state.query.dish_recommeds_rel_ratio_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>店均推荐数增长率：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.avg_recommeds_rel_ratio_min"></el-input>-
            <el-input type="number" v-model="state.query.avg_recommeds_rel_ratio_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>总推荐数：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.dish_recommends_num_min"></el-input>-
            <el-input type="number" v-model="state.query.dish_recommends_num_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>店均推荐数：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.avg_recommends_num_min"></el-input>-
            <el-input type="number" v-model="state.query.avg_recommends_num_max"></el-input>
          </div>
        </div>
        <div class="li">
          <h6>城市增长率：</h6>
          <div class="int_box">
            <el-input type="number" v-model="state.query.city_ratio_min"></el-input>-
            <el-input type="number" v-model="state.query.city_ratio_max"></el-input>
          </div>
        </div>
      </div>
      <div class="btn_box">
        <el-button type="info" @click="resetFunc">重置</el-button>
        <el-button type="primary" @click="search">搜索</el-button>
      </div>
    </div>
    <!-- 表格 -->
    <div class="row_box">
      <div class="tit_box">
        <small-title tit="星探结果"></small-title>
        <el-button type="primary" @click="exportExcel">导出excel<el-icon class="el-icon--right">
            <Right />
          </el-icon>
        </el-button>
      </div>
      <el-tabs v-model="state.tableActive" class="demo-tabs">
        <!-- 评分信息 -->
        <el-tab-pane label="评分信息" name="score">
          <div class="table_box">
            <el-table :data="tableState.score.tableData" stripe border style="width: 100%" height="600">
              <el-table-column prop="material_standard" label="原料标准词" min-width="120" show-overflow-tooltip
                v-if="state.menuActive !== 'flavor'" />
              <el-table-column prop="flavor_standard" label="风味标准词" min-width="120" show-overflow-tooltip
                v-if="state.menuActive !== 'material'" />
              <el-table-column prop="total_score" label="总得分" show-overflow-tooltip />
              <el-table-column prop="shop_num_score" label="门店数得分" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="brand_num_score" label="品牌数得分" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="province_num_score" label="省份数得分" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="city_num_score" label="城市数得分" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="region_score" label="集中度得分" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="recommends_rel_ratio_score" label="总推荐数比率得分" show-overflow-tooltip min-width="100"
                align="center" />
              <el-table-column prop="avg_recommends_rel_ratio_score" label="店均推荐数增长率得分" show-overflow-tooltip
                min-width="100" align="center" />
              <el-table-column prop="growth_score" label="成长性得分" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column prop="dish_recommends_num_score" label="总推荐数得分" show-overflow-tooltip min-width="115"
                align="center" />
              <el-table-column prop="avg_recommends_num_score" label="店均推荐数得分" show-overflow-tooltip min-width="120"
                align="center" />
              <el-table-column prop="hot_score" label="热度得分" show-overflow-tooltip min-width="100" align="center" />
              <el-table-column label="操作" show-overflow-tooltip min-width="110" align="center" fixed="right">
                <template #default="scope">
                  <el-button type="text" style="color: #F56C6C;" @click="topOpen(scope.row)">TOP300菜品</el-button>
                </template>
              </el-table-column>
              <template #empty>
                <el-empty description="暂无数据"></el-empty>
              </template>
            </el-table>
            <div class="page_box">
              <el-pagination layout="prev, pager, next" background :total="tableState.score.total"
                :page-size="tableState.score.query.pageSize" v-model:current-page="tableState.score.query.pageNum"
                @current-change="pageChange">
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <!-- 地域分布 -->
        <el-tab-pane label="地域分布" name="region">
          <div class="table_box">
            <el-table :data="tableState.region.tableData" stripe border style="width: 100%" height="600">
              <el-table-column prop="material_standard" label="原料标准词" min-width="120" show-overflow-tooltip
                v-if="state.menuActive !== 'flavor'" />
              <el-table-column prop="flavor_standard" label="风味标准词" min-width="120" show-overflow-tooltip
                v-if="state.menuActive !== 'material'" />
              <el-table-column v-for="item in tableState.region.titleData" :prop="item" :label="item" />
              <template #empty>
                <el-empty description="暂无数据"></el-empty>
              </template>
            </el-table>
            <div class="page_box">
              <el-pagination layout="prev, pager, next" background :total="tableState.region.total"
                :page-size="tableState.region.query.pageSize" v-model:current-page="tableState.region.query.pageNum"
                @current-change="pageChange">
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <!-- 季度趋势 -->
        <el-tab-pane label="季度趋势" name="trend">
          <div class="table_box">
            <el-table :data="tableState.trend.tableData" stripe border style="width: 100%" height="600">
              <el-table-column prop="material_standard" label="原料标准词" min-width="120" show-overflow-tooltip
                v-if="state.menuActive !== 'flavor'" />
              <el-table-column prop="flavor_standard" label="风味标准词" min-width="120" show-overflow-tooltip
                v-if="state.menuActive !== 'material'" />
              <el-table-column v-for="item in tableState.trend.titleData" :prop="item" :label="item" />
              <template #empty>
                <el-empty description="暂无数据"></el-empty>
              </template>
            </el-table>
            <div class="page_box">
              <el-pagination layout="prev, pager, next" background :total="tableState.trend.total"
                :page-size="tableState.trend.query.pageSize" v-model:current-page="tableState.trend.query.pageNum"
                @current-change="pageChange">
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <!-- 季度Top菜品 -->
        <el-tab-pane label="季度Top" name="top">
          <div class="table_box">
            <el-table :data="tableState.top.tableData" stripe border style="width: 100%" height="600">
              <el-table-column v-for="item in tableState.top.titleData" :prop="item" :label="item" />
              <template #empty>
                <el-empty description="暂无数据"></el-empty>
              </template>
            </el-table>
            <div class="page_box">
              <el-pagination layout="prev, pager, next" background :total="tableState.top.total"
                :page-size="tableState.top.query.pageSize" v-model:current-page="tableState.top.query.pageNum"
                @current-change="pageChange">
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <!-- 权重配置弹框 -->
  <weight-dialog :show="weightState.show" @close="weightClose" @reDefualt="reDefualt"
    @setStorageConfig='setStorageConfig'></weight-dialog>
  <!-- top300弹框 -->
  <top-dialog :show="topState.show" :row="topState.row" :list="topState.list" :loading="topState.loading"
    @close="topClose" @downloadTop300="downloadTop300"></top-dialog>
</template> 

<script setup>
import MyHeader from "./MyHeader.vue";
import SmallTitle from "@/components/common/SmallTitle.vue";
import { reactive } from "vue";
import outLoginHook from "@/utils/outLoginHook.js";// 退出登录
import useParams from "./useParams.js";// 参数
import { Right } from "@element-plus/icons-vue";
import useMenuChange from "./useMenuChange.js";// 头部菜单切换
import WeightDialog from "./weight-config/WeightDialog.vue";// 权重配置
import useWeight from "./weight-config/useWeight.js";
import useTableData from './useTableData';// 表格数据
import useExportExcel from './useExportExcel';// 导出
import TopDialog from "./top-dialog/TopDialog.vue";// top300弹框
import useTop from './top-dialog/useTop';

const state = reactive({
  loading: false,
  menuList: [
    { id: 'material', name: "原料" },
    { id: 'flavor', name: "风味" },
    { id: 'materialAndFlavor', name: "原料+风味" }
  ],
  menuActive: 'material',//顶部菜单选择
  tableActive: 'score',//table选择项
  query: {
    // 数据范围筛选
    batch: "", //批次
    province: [],//省份
    city: [],//城市
    ratioType: "环比", //比率
    mainType: [], //餐饮类型
    cuisineType: [],//菜系类型
    cuisine: [],//菜系
    materialMainType: [],//原料大类
    materialType: [],//原料二级类型
    materialSubType: [],//原料细类
    flavorMainType: [],//风味大类
    flavorType: [],//风味二级类型
    processMainType: [],//制作工艺
    styleType: [], //奉客形式
    shop_num_min: null,// 品牌门店数
    shop_num_max: null,
    // 计算结果筛选
    brand_num_min: null, //品牌数
    brand_num_max: null,
    province_num_min: null, //省份数
    province_num_max: null,
    city_num_min: null, //城市数
    city_num_max: null,
    brand_ratio_min: null, //品牌增长率
    brand_ratio_max: null,
    hot_score_min: null, //热度得分
    hot_score_max: null,
    dish_recommeds_rel_ratio_min: null, //总推荐数增长率
    dish_recommeds_rel_ratio_max: null,
    avg_recommeds_rel_ratio_min: null, //店均推荐数增长率
    avg_recommeds_rel_ratio_max: null,
    dish_recommends_num_min: null, //总推荐数
    dish_recommends_num_max: null,
    avg_recommends_num_min: null, //店均推荐数
    avg_recommends_num_max: null,
    city_ratio_min: null, //城市增长率
    city_ratio_max: null,
  }
});

// 重置
function resetFunc() {
  // 重置条件
  menuChange(state.menuActive);
  // 清空表格
  clearTable()
}

// 权重配置
const { state: weightState, open: weightOpen, close: weightClose, reDefualt, setStorageConfig, getWeightConfig } = useWeight()

// 表格数据
const { state: tableState, search, pageChange, clearTable } = useTableData(state, getWeightConfig)

// 导出
const { exportExcel } = useExportExcel(state, tableState);

// 顶部菜单切换
const { menuChange } = useMenuChange(state, clearTable);

// 退出登录
const { outLoginFun } = outLoginHook();

// 条件选项
const { state: paramsState, changeProvince, changeMainType, changeCuisineType, changeMaterialMainType, changeMaterialType, changeflavor } = useParams(state);

// top300弹框
const { state: topState, close: topClose, open: topOpen, downloadTop300 } = useTop(state, getWeightConfig);

</script>

<style lang="scss" scoped>
.home {
  padding: 40px;
  padding-top: 80px;

  .row_box {
    background: #fff;
    border-radius: 14px;
    padding: 20px;
    margin-bottom: 10px;

    .tit_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .search_box {
      display: flex;
      flex-wrap: wrap;
      padding: 0 20px;
      padding-top: 20px;

      .li {
        margin-right: 20px;
        margin-bottom: 10px;

        h6 {
          font-weight: normal;
          color: #818a9b;
          padding-bottom: 6px;
          font-size: 14px;
        }

        .el-select {
          width: 200px;
        }

        :deep(.el-input__inner) {
          border-radius: 7px;
        }

        .int_box {
          display: flex;
          align-items: center;

          .el-input {
            width: 98px;
          }
        }
      }
    }

    .btn_box {
      text-align: center;
      padding-top: 10px;
    }
  }

  .page_box {
    text-align: right;
    padding: 20px 0;
  }
}

:deep(.el-table__empty-block) {
  width: 100% !important;
}

// 滚动条的宽度
:deep(.el-table__body-wrapper::-webkit-scrollbar) {
  width: 10px; // 横向滚动条
  height: 10px; // 纵向滚动条 必写
}

// 滚动条的滑块
:deep(.el-table__body-wrapper::-webkit-scrollbar-thumb) {
  background-color: #ddd;
  border-radius: 5px;
  cursor: pointer;
}
</style>
